.flag-selector {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  row-gap: 1rem;
}

@media (max-width: 1040px) {
  .flag-selector {
    grid-template-columns: 1fr 50px;
  }
}

.flag-selector.has-options {
  grid-template-columns: 1fr;
}

.ld-identifier {
  margin-left: 0.1rem;
  font-size: 12px;
}

.ld-identifier svg {
  vertical-align: super;
}
