#embedded-veriff-root {
  border: solid 2px var(--genoma-colors-backgroundBrand);
}

.InContextEmbeddedModeActions {
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  align-items: center;
  column-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2rem;
  margin: 2rem 0;
}

.InContextEmbeddedModeActionsGridTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

@media (max-width: 767px), (hover: none) {
  .InContextEmbeddedModeActions {
    grid-template-columns: repeat(1, 1fr);
  }
}
