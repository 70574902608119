body,
html {
  margin: 0;
  padding: 0;
}

.app-container {
  max-width: 1040px;
  margin: 0 auto;
  word-break: break-all;
}

@media (max-width: 1040px) {
  .app-container {
    padding: 0 1rem;
  }
}

.error-banner {
  margin: 0.5rem auto;
  word-break: break-word;
}

.app-logo {
  text-align: center;
  height: 80px;
  line-height: 80px;
}

.app-logo svg {
  vertical-align: middle;
}

/* utils */
.m-l {
  margin-left: 0.5rem;
}
.m-r {
  margin-right: 0.5rem;
}
.m-t {
  margin-top: 0.5rem;
}
.m-b {
  margin-bottom: 0.5rem;
}
.no-margin {
  margin: 0;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.w-full {
  width: 100%;
}
.min-w-max {
  min-width: max-content;
}
