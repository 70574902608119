.divider {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--genoma-color-primary-400);
}

.all-flags {
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid var(--genoma-color-primary-500);
}

.all-flags-section {
  margin-top: 64px;
}

.quick-search {
  width: fit-content;
  float: left;
  flex: 1;
  margin-right: 25px;
}

.quick-search-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 8px;
}

.quick-search > div {
  position: absolute;
}

.clear-search {
  margin-top: 10px;
}

.delete-category:hover {
  background: var(--genoma-color-base-200);
}

.category-button:hover {
  background: var(--genoma-color-base-200);
}

.clear-all {
  align-self: flex-end;
  margin-bottom: 10px;
}

.collapsible-flags {
  height: fit-content;
}

.enabled-flags {
  white-space: nowrap;
}

.collapsible-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

.categories-group  {
  grid-template-columns: 1fr 3fr;
}

.flex-column {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  align-items: flex-start;
}

.feature-flag-category {
  display: grid;
  grid-template-columns: 7fr 1fr;
  width: 100%;
  text-align: left;
}

.feature-flag-category button {
  width: fit-content;
  margin: 0;
  justify-content: flex-start;
}

.enabled-flags-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

@media (max-width: 767px) {
  .collapsible-group {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }

  .categories-group {
    grid-template-columns: 1fr;
  }

  .categories-group button {
    width: 100%;
  }

  .clear-all {
    float: left;
  }

  .enabled-flags-container {
    justify-content: center;
    margin: 16px 16px 16px 0;
  }

}
