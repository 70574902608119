@media (min-width: 768px) {
  .grid-double-desktop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr !important;
    grid-gap: 8px;
  }
}

form > section {
  margin-bottom: 2rem;
}

form > section:last-child {
  margin-bottom: 0;
}

#g-re-captcha > *:last-child {
  margin: 0 auto 14px;
}

.grid-panel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
  row-gap: 2rem;
  column-gap: 2rem;
  align-items: center;
}

.grid-options {
  display: grid;
  grid-auto-flow: column;
  justify-items: start;
  margin-top: 6px;
  grid-gap: 12px;
}

.grid-options-launch-veriff {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
}

@media (max-width: 767px) {
  .grid-options {
    grid-auto-flow: row;
  }
}

.grid-column {
  display: grid;
  grid-template-rows: 88px;
  grid-row-gap: 16px;
  padding: 24px 0;
}

.text-center {
  text-align: center;
}

.grid-double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

.grid-double-desktop {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.break-word {
  word-break: break-word;
}

.bold {
  font-weight: bold;
}

.display-contents {
  display: contents;
}

.flex-center {
  display: flex !important;
  flex-direction: column;
}

.title {
  font-size: 18px;
  margin: 4px;
}

.veriff-title-container {
  background: var(--genoma-color-positive-300);
  padding: 24px 1rem 40px;
}

.veriff-title-container__header {
  margin: 0 0 8px;
}

.veriff-title-container__text {
  color: var(--genoma-color-text-secondary);
  max-width: 652px;
  margin: 0.5rem auto 0;
}

.demo-illustration {
  display: block;
  margin: 0 auto;
  position: relative;
  top: -32px;
  height: 240px;
}

.prod-form {
  max-width: 553px;
  margin: 0 auto 1rem;
}

.prod-form small {
  word-break: break-word;
}

.terms-and-conditions {
  max-width: calc(553px + 2rem);
  margin: 0 auto 24px;
  padding: 0 1rem;
}

.terms-and-conditions section {
  margin: 0.25rem auto;
}

.terms-and-conditions p {
  color: var(--genoma-color-text-secondary);
  font-size: var(--genoma-font-size-caption);
  margin: 4px 0;
}

.terms-and-conditions a {
  color: var(--genoma-color-accent);
}

.terms-and-conditions b {
  font-weight: 500;
}
